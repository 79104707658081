html {
    font-size: 16px;
}

* {
    box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
}

ol,
ul {
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

input,
button, textarea {
    outline: none;
}
